import {useState, useEffect} from 'react';
import useIdleCallback from './useIdleCallback';

const useScrolled = orAfterTimeout => {
  const [scrolled, setScrolled] = useState(false);

  const doSetScrolled = useIdleCallback(() => setScrolled(true), [setScrolled]);

  useEffect(() => {
    if (scrolled) return;

    let timeout;

    const onScroll = e => {
      doSetScrolled();
    };

    if (orAfterTimeout) {
      timeout = setTimeout(() => {
        doSetScrolled();
      }, orAfterTimeout);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      if (timeout) clearTimeout(timeout);
    };
  }, [scrolled, doSetScrolled]);

  return scrolled;
};

export default useScrolled;
