import React from 'react';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import loadable from '@loadable/component';

import useScrolled from 'helpers/useScrolled';
import useParseSeomatic from 'helpers/useParseSeomatic';

const DesktopNav = loadable(() => import('./navigation/DesktopNav'));
const MobileNav = loadable(() => import('./navigation/MobileNav'));
const Footer = loadable(() => import('./Footer'));

export default function Layout({children, hasContact, preloadFooter, seomatic}) {
  const {isLarge} = useBreakpoint();

  const scrolled = useScrolled();

  const {metaJsonLdArray, metaTitle, metaTag, metaLink, metaScriptBody} = useParseSeomatic(seomatic);

  const {
    allCraftNodeInterface: {nodes: navItems},
  } = useStaticQuery(graphql`
    query MainNavigationQuery {
      allCraftNodeInterface (filter: {navHandle: {eq: "mainNavigation"}, level: {eq: 1}, enabled: {eq: true}}, sort: {fields: [rgt], order: ASC}) {
        nodes {
          ... on Craft_mainNavigation_Node {
            id
            nodeUri
            title
            remoteChildren {
              ... on Craft_mainNavigation_Node {
                id
                nodeUri
                title
                remoteChildren {
                  ... on Craft_mainNavigation_Node {
                    id
                    title
                    nodeUri
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}>
        {metaTitle}
        {metaTag}
        {metaLink}
        {metaJsonLdArray &&
          metaJsonLdArray.map((script, index) => (
            <script type="application/ld+json" key={`json-ld-${index}`}>
              {script}
            </script>
          ))}
        <script type="text/javascript" src="https://assets.goodfirms.co/assets/js/widget.min.js"></script>
        <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js" defer></script>
        {seomatic?.metaScriptContainer && (
          <script>
          {`${JSON.parse(seomatic.metaScriptContainer).script.replace(/<\/?script\b[^<]*(?:(?!<\/script>)<[^<]*)?>/gi, "")}`}
          </script>
        )}
      </Helmet>
      {isLarge ? <DesktopNav navItems={navItems} /> : <MobileNav navItems={navItems} />}
      <div>{children}</div>
      {(scrolled || preloadFooter) && <Footer hasContact={hasContact} />}
      {metaScriptBody}
    </div>
  );
}

export const seomaticQuery = graphql`
  fragment SeomaticFields on Craft_SeomaticType {
    metaTitleContainer
    metaTagContainer
    metaJsonLdContainer
    metaScriptContainer
    metaLinkContainer
  }
`;
