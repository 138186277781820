import React from 'react';
import {motion} from 'framer-motion';
import {
  fadeElement,
  fade,
  pageVariants,
  pageTransition,
} from '../components/Transitions';

export const PageHeader = ({heading, subheading, time, content}) => {
  return (
    <div className="relative flex flex-col pl-6 pr-6 text-white background bg-gradient-to-r from-black to-dark font-display">
      <div className="w-full max-w-screen-xl pb-8 m-auto pt-28 md:pt-44 md:pb-12">
        <h1 className="inline-block mb-1 font-bold prose-2xl text-transparent lg:mb-0 bg-gradient-to-r from-orange to-pink bg-clip-text md:prose-3xl lg:prose-4xl">
          {heading}
        </h1>
        { !!subheading && <h3
          className="max-w-4xl leading-normal prose-lg sm:prose-2xl md:prose-3xl"
          dangerouslySetInnerHTML={{__html: subheading}}
        /> }
        {time && <div><time className="max-w-4xl">{time}</time></div>}
      </div>
      {content}
    </div>
  );
};

export const SectionHeader = ({heading, subheading, light}) => {
  return (
    <div className={`pl-6 pr-6 section-header ${
      light ? 'text-white' : 'text-gray-600'
    }`}>
      <h2 className="mb-2 font-bold prose-2xl text-center sm:prose-3xl font-display">
        {heading}
      </h2>
      <div
        className={`text-left md:text-center prose-lg lg:prose-2xl font-medium leading-6 font-display `}
        dangerouslySetInnerHTML={{__html: subheading}}
      />
    </div>
  );
};

export const MainHeroHeader = ({
  headingTopLevel,
  headingHighlightLevel,
  headingHighlightColour,
  headingBottomLevel,
}) => {
  const highlightClasses = [
    'font-semibold text-transparent bg-gradient-to-r bg-clip-text',
    (headingHighlightColour === 'orangeGradient' || !headingHighlightColour) && 'from-orange to-pink',
    headingHighlightColour === 'blueGradient' && 'from-blue to-purple',
  ].filter(Boolean).join(' ');
  return (
    <motion.h1
      variants={fadeElement}
      transition={fade}
      animate="visible"
      className="flex-none leading-8 prose-xl text-white md:leading-normal sm:prose-2xl md:prose-3xl lg:prose-4xl xl:prose-5xl">
      {headingTopLevel}&nbsp;
      <br />
      <span className={highlightClasses}>
        {headingHighlightLevel}&nbsp;
      </span>
      <br />
      {headingBottomLevel}
    </motion.h1>
  );
};
