import {useCallback, useEffect, useState, useRef} from 'react';

const useIdleCallback = (callback, options) => {
  const callbackRef = useRef(callback);
  const [handle, setHandle] = useState();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      if (handle) {
        if (!window.cancelIdleCallback) {
          clearTimeout(handle);
        } else {
          window.cancelIdleCallback(handle);
        }
      }
    };
  }, [handle]);

  return useCallback(
    (...params) => {
      let handler;

      if (window.requestIdleCallback) {
        handler = window.requestIdleCallback(() => callbackRef.current(...params), options);
      } else {
        handler = setTimeout(() => callbackRef.current(...params), 200);
      }
      setHandle(handler);
    },
    [options]
  );
};

export default useIdleCallback;
