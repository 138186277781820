import {useMemo} from 'react';
import parse from 'html-react-parser';

const useParseSeomatic = seomatic =>
  useMemo(() => {
    const {metaJsonLdContainer = '', metaTitleContainer = '', metaTagContainer = '', metaLinkContainer = '', metaScriptContainer = ''} = seomatic ?? {};

    const splitJsonLdStringIntoArray = metaJsonLdContainer.replace(/\<\/script\>/g, '').split(`<script type="application/ld+json">`);

    let parsedMetaScriptContainer = {};
    try {
      parsedMetaScriptContainer = JSON.parse(metaScriptContainer || '{}');
    } catch (e) {
      console.error('Failed parsing seomatic scripts:', e, metaScriptContainer);
      parsedMetaScriptContainer = {};
    }

    return {
      metaJsonLdArray: splitJsonLdStringIntoArray,
      metaTitle: parse(metaTitleContainer),
      metaTag: parse(metaTagContainer),
      metaLink: parse(metaLinkContainer),
      metaScriptHead: parse(parsedMetaScriptContainer?.script ?? ''),
      metaScriptBody: parse(parsedMetaScriptContainer?.bodyScript ?? ''),
    };
  }, [seomatic]);

export default useParseSeomatic;
